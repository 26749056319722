import '../App.css';
import AboutSection from '../components/AboutSection';
import Navbar from '../components/Navbar';
import ResumeSection from '../components/ResumeSection';

function App() {
  return (
  <>
  <Navbar/>
  <AboutSection/>
  {/* <div className='App-header'>
    <h1>WELCOME TO MY PORTFOLIO</h1>
  </div> */}
  <div className=''>
  <ResumeSection/>
  </div>
  </>
  );
}

export default App;
