import React from 'react';
import { FaGithub, FaTwitter, FaLinkedin, FaDownload } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import DP from '../assests/images/rahmath-DP.png'

const AboutSection = () => {
  return (
    <section id="about" className="about-area text-white">
      <div className="container mx-auto px-4 py-16">
        <div className="grid lg:grid-cols-2 gap-8">
          {/* Profile Section */}
          <div className="flex flex-col items-center text-center">
            <div className="about-image-part">
              <img
                src={DP}
                alt="About Me"
                className="shadow-lg w-48 h-48 mb-4"
              />
              <h2 className="text-3xl font-bold mb-2">Rahmathullah Qaaemuddin</h2>
              <p className="text-lg text-gray-400">I am a Web Developer based Hyderabad, India.</p>
              <div className="about-social flex justify-center mt-4 space-x-4">
                <a href="/" className="text-blue-600">
                  <FaGithub size={20} />
                </a>
                <a href="/" className="text-blue-700">
                  <FaLinkedin size={20} />
                </a>
                <a href="/" className="text-blue-400">
                  <FaTwitter size={20} />
                </a>
              </div>
            </div>
          </div>
          {/* About Content Section */}
          <div>
            <div className="about-content-part">
              <p className="text-2xl font-semibold mb-4">Hello There!</p>
              <h2 className="text-4xl font-bold mt-2">
                I’m Rahmathullah Qaaemuddin, a product designer crafting user-centric design with pixel-perfect precision.
              </h2>
              <div className="address-field mt-4">
                <ul className="space-y-2">
                  <li className="flex items-center text-gray-400">
                    <MdLocationOn size={14} className="mr-2" />
                    Available for Freelancing
                  </li>
                </ul>
              </div>
              <div className="hero-btns mt-6">
                <a href="/contact" className="theme-btn text-white py-2 px-4 rounded-lg flex items-center">
                  Download CV <FaDownload size={16} className="ml-2" />
                </a>
              </div>
            </div>
            <div className="about-content-part-bottom mt-10">
              <h2 className="text-2xl font-bold mb-4">Companies I Worked With</h2>
              <div className="company-list">
                <div className="scroller overflow-hidden">
                  <div className="scroller__inner flex space-x-8">
                    {/* Company Logos */}
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUJggg==" alt="Company 1" className="h-12" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHuQmCC" alt="Company 2" className="h-12" />
                    {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGoAAAAjCAYAAAB8dp8zAAAIlUlEQVRoge2bDbBWRRnHf8Ilguuggg==" alt="Company 3" className="h-12" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAAAeCAYAAAD5AOomAAAID0lEQVRoge1bDZBPVRT/7X9ZYRWiSBWNlnsr06H5TLI1hpEw9aKipzF1qkB5AcDf3dLmmJ5iybAAAAAASUVORK5CYII=" alt="Company 4" className="h-12" /> */}
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAUVORK5CYII=" alt="Company 5" className="h-12" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIUAAErkJggg==" alt="Company 6" className="h-12" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;