import React from 'react';

const ResumeSection = () => {
  return (
    <section id="resume" className="resume-area py-12">
      <div className="container mx-auto">
        <div className="resume-items text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Experience Section */}
            <div className="single-resume">
              <h2 className="text-2xl font-bold mb-4">Experience</h2>
              <div className="experience-list space-y-6">
                {/* Experience Item */}
                <div className="resume-item flex space-x-4 items-start">
                  <div className="icon text-blue-600">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      className="remixicon"
                    >
                      <path d="M3 18.5V5C3 3.34315 4.34315 2 6 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H6.5C4.567 22 3 20.433 3 18.5ZM19 20V17H6.5C5.67157 17 5 17.6716 5 18.5C5 19.3284 5.67157 20 6.5 20H19ZM5 15.3368C5.45463 15.1208 5.9632 15 6.5 15H19V4H6C5.44772 4 5 4.44772 5 5V15.3368Z"></path>
                    </svg>
                  </div>
                  <div className="content">
                    <span className="block text-gray-500">2021 - Present</span>
                    <h4 className="text-xl font-semibold">Web Designer</h4>
                    <span className="company text-gray-500">
                      Themeforest Market
                    </span>
                  </div>
                </div>
                {/* Repeat for other experience items */}
                <div className="resume-item flex space-x-4 items-start">
                  <div className="icon text-blue-600">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="remixicon"
                    >
                      <path d="M3 18.5V5C3 3.34315 4.34315 2 6 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H6.5C4.567 22 3 20.433 3 18.5ZM19 20V17H6.5C5.67157 17 5 17.6716 5 18.5C5 19.3284 5.67157 20 6.5 20H19ZM5 15.3368C5.45463 15.1208 5.9632 15 6.5 15H19V4H6C5.44772 4 5 4.44772 5 5V15.3368Z"></path>
                    </svg>
                  </div>
                  <div className="content">
                    <span className="block text-gray-500">2021 - 2023</span>
                    <h4 className="text-xl font-semibold">Marketing Expert GRP</h4>
                    <span className="company text-gray-500">
                      Envato Theme Developer
                    </span>
                  </div>
                </div>
                <div className="resume-item flex space-x-4 items-start">
                  <div className="icon text-blue-600">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="remixicon"
                    >
                      <path d="M3 18.5V5C3 3.34315 4.34315 2 6 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H6.5C4.567 22 3 20.433 3 18.5ZM19 20V17H6.5C5.67157 17 5 17.6716 5 18.5C5 19.3284 5.67157 20 6.5 20H19ZM5 15.3368C5.45463 15.1208 5.9632 15 6.5 15H19V4H6C5.44772 4 5 4.44772 5 5V15.3368Z"></path>
                    </svg>
                  </div>
                  <div className="content">
                    <span className="block text-gray-500">2021 - 2022</span>
                    <h4 className="text-xl font-semibold">Web Designer</h4>
                    <span className="company text-gray-500">
                      Web Developer & Business Partner
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Education Section */}
            <div className="single-resume">
              <h2 className="text-2xl font-bold mb-4">Education</h2>
              <div className="experience-list space-y-6">
                <div className="resume-item flex space-x-4 items-start">
                  <div className="icon text-blue-600">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="remixicon"
                    >
                      <path d="M3 18.5V5C3 3.34315 4.34315 2 6 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H6.5C4.567 22 3 20.433 3 18.5ZM19 20V17H6.5C5.67157 17 5 17.6716 5 18.5C5 19.3284 5.67157 20 6.5 20H19ZM5 15.3368C5.45463 15.1208 5.9632 15 6.5 15H19V4H6C5.44772 4 5 4.44772 5 5V15.3368Z"></path>
                    </svg>
                  </div>
                  <div className="content">
                    <span className="block text-gray-500">2013 - 2015</span>
                    <h4 className="text-xl font-semibold">Bachelor Degree of Information Technology</h4>
                    <span className="company text-gray-500">State University Bangladesh</span>
                  </div>
                </div>
                <div className="resume-item flex space-x-4 items-start">
                  <div className="icon text-blue-600">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="remixicon"
                    >
                      <path d="M3 18.5V5C3 3.34315 4.34315 2 6 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H6.5C4.567 22 3 20.433 3 18.5ZM19 20V17H6.5C5.67157 17 5 17.6716 5 18.5C5 19.3284 5.67157 20 6.5 20H19ZM5 15.3368C5.45463 15.1208 5.9632 15 6.5 15H19V4H6C5.44772 4 5 4.44772 5 5V15.3368Z"></path>
                    </svg>
                  </div>
                  <div className="content">
                    <span className="block text-gray-500">2021 - 2023</span>
                    <h4 className="text-xl font-semibold">Web Designer</h4>
                    <span className="company text-gray-500">XYZ Design Institute</span>
                  </div>
                </div>
                <div className="resume-item flex space-x-4 items-start">
                  <div className="icon text-blue-600">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="remixicon"
                    >
                      <path d="M3 18.5V5C3 3.34315 4.34315 2 6 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22H6.5C4.567 22 3 20.433 3 18.5ZM19 20V17H6.5C5.67157 17 5 17.6716 5 18.5C5 19.3284 5.67157 20 6.5 20H19ZM5 15.3368C5.45463 15.1208 5.9632 15 6.5 15H19V4H6C5.44772 4 5 4.44772 5 5V15.3368Z"></path>
                    </svg>
                  </div>
                  <div className="content">
                    <span className="block text-gray-500">2021 - 2022</span>
                    <h4 className="text-xl font-semibold">Web Design & Development</h4>
                    <span className="company text-gray-500">ABC Tech Institute</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Repeat the above sections for other content */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResumeSection;
