import React, { useState } from 'react';
import { IoIosMenu, IoIosClose } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate()

  const onToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLoginClick = () => {
    navigate('/contact'); 
  };

  return (
    <>
    <div className="font-[Poppins] bg-gradient-to-t from-[#882c86] to-[#2069e2]">
    <header className="bg-black text-white ">
      <nav className="flex justify-between items-center w-[92%] h-[50px] mx-auto">
        <div>
          {/* <img className="w-16 cursor-pointer" src="" alt="Logo" /> */}
          <Link to="/"> <h5>AcadInsight</h5> </Link>
        </div>
        <div
          className={` bg-black nav-links duration-500 md:static absolute md:min-h-fit min-h-[60vh] left-0 ${menuOpen ? 'top-[9%]' : 'top-[-100%]'} md:w-auto w-full flex items-center px-5`}>
          <ul className="flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-8 text-xl">
          <li><Link className="hover:text-[#882c86]" to="/">Home</Link></li>
            <li><Link className="hover:text-[#2069e2]" to="/service">Service</Link></li>
            <li><Link className="hover:text-[#882c86]" to="/about-us">About</Link></li>
            <li><Link className="hover:text-[#882c86]" to="/works">Works</Link></li>
            <li><Link className="hover:text-[#2069e2]" to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className="flex items-center gap-6">
          <button className="bg-[#2069e2] text-white px-5 py-2 rounded-full hover:bg-[#882c86]"
          onClick={handleLoginClick}>Hire Me</button>
          {menuOpen 
            ? <IoIosClose className="text-3xl cursor-pointer md:hidden" onClick={onToggleMenu} /> 
            : <IoIosMenu className="text-3xl cursor-pointer md:hidden" onClick={onToggleMenu} />}
        </div>
      </nav>
    </header>
    </div>
    </>
  );
};

export default Navbar;